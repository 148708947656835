import {
  Group,
  Inject,
  Page,
  PageSettingsModel,
  Sort,
  ColumnDirective,
  ColumnsDirective,
  Filter,
  FilterSettingsModel,
  GridComponent,
  SortSettingsModel,
  ExcelExport,
  Resize,
  ToolbarItems,
  PdfExport,
  Search,
  Toolbar,
  Grid,
} from "@syncfusion/ej2-react-grids";
import moment from "moment";

type propsType = {
  historicData: any[];
};

function DataGrid({ historicData }: propsType) {
  let grid: Grid | null;
  const toolbar: ToolbarItems[] = [
    "ExcelExport",
    // "PdfExport",
    // "CsvExport",
    "Search",
    // "Print",
  ];

  const toolbarClick = (args: any) => {
    if (grid === null || !args) return;

    if (grid && args.item.text === "Excel Export") {
      grid.excelExport({
        fileName: `CAAQMS-${moment().format("ll")}.xlsx`,
      });
    }
  };
  const pageSettings: PageSettingsModel = { pageSize: 15 };
  const filterSettings: FilterSettingsModel = {
    type: "Excel",
  };

  return (
    <GridComponent
      ref={(g) => (grid = g)}
      height={"100%"}
      dataSource={historicData}
      // allowPaging={true}
      // pageSettings={pageSettings}

      toolbar={toolbar}
      toolbarClick={toolbarClick}
      allowTextWrap
      filterSettings={filterSettings}
      allowSorting={true}
      allowFiltering={true}
      enableStickyHeader
      allowExcelExport
      allowPdfExport
      allowResizing
      enableAltRow
      enableHover
    >
      <ColumnsDirective>
        <ColumnDirective field="sl_no" width={120} headerText="Serial No." />
        {/* <ColumnDirective
          field="station_id"
          headerText="STATION ID"
          width={140}
        /> */}
        <ColumnDirective field="date" width={140} />

        <ColumnDirective field="hour" width={140} />

        <ColumnDirective field="minute" width={140} />
        <ColumnDirective field="so2" width={140} headerText="So2 (µg/m3)" />
        <ColumnDirective field="no2" width={140} headerText="No2 (µg/m3)" />
        <ColumnDirective field="pm25" width={150} headerText="PM2.5 (µg/m3)" />
        <ColumnDirective field="pm10" width={150} headerText="PM10 (µg/m3)" />
        <ColumnDirective field="co" width={140} headerText="CO (mg/m3)" />
        <ColumnDirective field="o3" width={140} headerText="O3 (µg/m3)" />
        <ColumnDirective field="no" width={140} headerText="NO (µg/m3)" />
        <ColumnDirective field="nox" width={140} headerText="NOx (µg/m3)" />
        <ColumnDirective field="nh3" width={140} headerText="NH3 (µg/m3)" />
        {/* <ColumnDirective
          field="created_at"
          headerText="CREATED AT"
          width={170}
        /> */}
        <ColumnDirective
          field="wind_speed_mps"
          headerText="Wind Speed (m/s)"
          width={170}
        />
        <ColumnDirective
          field="wind_direction"
          headerText="Wind Direction"
          width={160}
        />
        <ColumnDirective
          field="temperature"
          headerText="Temperature (°C)"
          width={160}
        />
        <ColumnDirective
          field="humidity"
          width={150}
          headerText="Humidity (%)"
        />
        <ColumnDirective
          field="pressure"
          width={160}
          headerText="Pressure (mBar)"
        />
        <ColumnDirective
          field="rainfall_intensity"
          headerText="Rainfall Intensity (mm)"
          width={190}
        />
        <ColumnDirective
          field="solar_radiation"
          headerText="Solar Radiation (W/m2)"
          width={200}
        />
        <ColumnDirective
          field="benzene"
          width={160}
          headerText="Benzene (µg/m3)"
        />
        <ColumnDirective
          field="ethyl_benzene"
          width={190}
          headerText="Ethyl Benzene (µg/m3)"
        />
        <ColumnDirective
          field="toluene"
          width={160}
          headerText="Toluene (µg/m3)"
        />

        <ColumnDirective
          field="mp_xylene"
          width={180}
          headerText="MP Xylene (µg/m3)"
        />
        <ColumnDirective
          field="o_xylene"
          width={160}
          headerText="O Xylene (µg/m3)"
        />
        {/* <ColumnDirective field="AQI" width={140} /> */}
      </ColumnsDirective>
      <Inject
        services={[
          Sort,
          Filter,
          Resize,
          ExcelExport,
          PdfExport,
          Toolbar,
          Search,
          Page,
        ]}
      />
    </GridComponent>
  );
}

export default DataGrid;
