import { Dispatch, SetStateAction, useEffect, useState } from "react";
import Select from "react-select";
import moment, { Moment } from "moment";
import { DateTimePickerComponent } from "@syncfusion/ej2-react-calendars";
import { VscLoading } from "react-icons/vsc";
import { IStnData } from "./Map";

const options2 = [
  { value: "15min", label: "15 Minute" },
  { value: "hour", label: "Hourly" },
  { value: "24hour", label: "24-Hourly" },
];

const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: "#ffffff",
    border: state.isFocused ? 0 : 0,
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: state.isFocused ? 0 : 0,
    },
  }),
};

type propsType = {
  stnData: IStnData[];
  setHistoricData: Dispatch<SetStateAction<never[]>>;
};

function Filters({ stnData, setHistoricData }: propsType) {
  const [StartDateTime, setStartDateTime] = useState<Moment>(
    moment().subtract(1, "day")
  );
  const [EndDateTime, setEndDateTime] = useState<Moment>(moment());
  const [stationOptions, setStationOptions] = useState<any[]>([]);
  const [selectedStationId, setSelectedStationId] = useState<any>(1);
  const [selectedCriteria, setSelectedCriteria] = useState("15min");
  const [loadingData, setLoadingData] = useState(false);
  const [seclectDev, setSelectdev] = useState({
    label: stnData[0]?.location,
    value: stnData[0]?.id,
  });

  const handleFind = async (devId: any) => {
    try {
      setLoadingData(true);
      const res = await fetch(
        `https://caaqms.wbpcb.wtlprojects.com:9100/v1.0/sens/real/get_${selectedCriteria}_avg?start_time=${StartDateTime.format(
          "YYYY-MM-DD HH:mm:ss"
        )}&end_time=${EndDateTime.format("YYYY-MM-DD HH:mm:ss")}&station_id=${
          devId || selectedStationId
        }`
      );
      const json = await res.json();
      setLoadingData(false);

      const temp = json?.data.map((j: any, index: number) => ({
        ...j,
        sl_no: index + 1,
        date: moment(j.date).format("DD-MM-yyyy"),
        no: j.no || j.no_avg,
        benzene: j.benzene || j.benzene_avg,
        rainfall_intensity: j.rainfall_intensity || j.rainfall_intensity_sum,
        ethyl_benzene: j.ethyl_benzene || j.ethyl_benzene_avg,
        toluene: j.toluene || j.toluene_avg,
        mp_xylene: j.mp_xylene || j.mp_xylene_avg,
        o_xylene: j.o_xylene || j.o_xylene_avg,
        solar_radiation: j.solar_radiation || j.solar_radiation_avg,
      }));

      setHistoricData(temp);
    } catch (error) {
      alert("Something went wrong");
      setLoadingData(false);
    }
  };

  useEffect(() => {
    stnData[0]?.id && handleFind(stnData[0]?.id);
    setStationOptions(stnData.map((k) => ({ label: k.location, value: k.id })));
    setSelectdev({
      label: stnData[0]?.location,
      value: stnData[0]?.id,
    });
    setSelectedStationId(stnData[0]?.id);
  }, [stnData]);

  return (
    <div className="grid grid-cols-1 sm:grid-cols-5 px-4 py-4 gap-2">
      <div className="">
        <p className="font-semibold text-lg text-white pl-1">Location</p>
        <Select
          styles={customStyles}
          theme={(theme) => ({
            ...theme,
            //   borderRadius: 0,
            colors: {
              ...theme.colors,
              // primary25: "#0D8f87",
              primary: "#0D8f87",
            },
          })}
          className="w-full text-black col-span-1 "
          isSearchable
          placeholder="Station Name"
          onChange={(e: any) => {
            setSelectedStationId(e.value);
            handleFind(e.value);
            setSelectdev(e);
          }}
          options={stationOptions}
          value={seclectDev}
        />
      </div>

      <div className="">
        <p className="font-semibold text-lg text-white pl-1">Criteria</p>
        <Select
          styles={customStyles}
          theme={(theme) => ({
            ...theme,
            //   borderRadius: 0,
            colors: {
              ...theme.colors,
              // primary25: "#0D8f87",
              primary: "#0D8f87",
            },
          })}
          className="w-full text-black col-span-1 "
          isSearchable
          placeholder="Criteria"
          defaultValue={{
            value: "15min",
            label: "15 Minute",
          }}
          onChange={(e: any) => setSelectedCriteria(e.value)}
          options={options2}
        />
      </div>
      <div className="">
        <p className="font-semibold text-lg text-white pl-1">Start Date Time</p>
        <DateTimePickerComponent
          id="startdatetimepicker"
          style={{
            height: 36,
          }}
          placeholder="Select a start date and time"
          value={StartDateTime.toDate()}
          change={(e) => {
            setStartDateTime(moment(e.value));
          }}
          openOnFocus={true}
        />
      </div>
      <div className="">
        <p className="font-semibold text-lg text-white pl-1">End Date Time</p>
        <DateTimePickerComponent
          id="enddatetimepicker"
          style={{
            height: 36,
          }}
          placeholder="Select an end date and time"
          value={EndDateTime.toDate()}
          change={(e) => {
            setEndDateTime(moment(e.value));
          }}
        />
      </div>

      <div
        onClick={() => {
          handleFind(selectedStationId);
        }}
        className="cursor-pointer  h-9 mt-7 ml-4  rounded-md hover:bg-slate-300 hover:shadow-md duration-300 bg-white "
      >
        {!!loadingData ? (
          <VscLoading className="animate-spin  mt-1 mx-auto" size="25px" />
        ) : (
          <p className="font-bold text-blue-500 font-sans text-center mt-1">
            Find
          </p>
        )}
      </div>
    </div>
  );
}

export default Filters;
