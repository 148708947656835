import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Filters from "../components/Filters";
import DataGrid from "../components/DataGrid";

function HistoryPage() {
  const [historicData, setHistoricData] = useState([]);
  const [stnData, setStnData] = useState([]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const res = await fetch(
          "https://caaqms.wbpcb.wtlprojects.com:9100/get_all_station"
        );

        const apiData = await res.json();

        setStnData(apiData?.data);
      } catch (error) {}
    };

    loadData();
  }, []);

  return (
    <div className="bg-gray-200 min-h-screen">
      <Header />
      <div className="bg-[#0D8f87]/80 w-full ">
        <Filters stnData={stnData} setHistoricData={setHistoricData} />
        <div
          className="px-4 pb-4 rounded-lg"
          style={{
            height: "77vh",
          }}
        >
          <DataGrid historicData={historicData} />
        </div>
      </div>
    </div>
  );
}

export default HistoryPage;
