import { useEffect, useState } from "react";

import "react-datepicker/dist/react-datepicker.css";
import Header from "../components/Header";
import InfoCard from "../components/InfoCard";
import Map from "../components/Map";
import ChatGraph from "../components/ChatGraph";
import AQIGauge from "../components/AQIGauge";
import AQILabelCard from "../components/AQILabelCard";
import BasicPollutant from "../components/BasicPollutant";
import { AllPollutant } from "../components/AllPollutant";
import Filters from "../components/Filters";
import DataGrid from "../components/DataGrid";
import { useLocation } from "react-router-dom";
import DeviceDropdown from "../components/DeviceDropdown";

export interface AirQualityData {
  SO2_MIN: string;
  SO2_MIN_SI: number;
  SO2_MAX: string;
  SO2_MAX_SI: number;
  SO2_AVG: string;
  SO2_AVG_SI: number;
  CO_MIN: string;
  CO_MIN_SI: number;
  CO_MAX: string;
  CO_MAX_SI: number;
  CO_AVG: string;
  CO_AVG_SI: number;
  CO_MAX_8H: string;
  CO_MAX_8H_SI: number;
  O3_MIN: string;
  O3_MIN_SI: number;
  O3_MAX: string;
  O3_MAX_SI: number;
  O3_AVG: string;
  O3_AVG_SI: number;
  O3_MAX_8H: string;
  O3_MAX_8H_SI: number;
  PM25_MIN: string;
  PM25_MIN_SI: number;
  PM25_MAX: string;
  PM25_MAX_SI: number;
  PM25_AVG: string;
  PM25_AVG_SI: number;
  PM10_MIN: string;
  PM10_MIN_SI: number;
  PM10_MAX: string;
  PM10_MAX_SI: number;
  PM10_AVG: string;
  PM10_AVG_SI: number;
  NO_MIN: string;
  NO_MAX: string;
  NO_AVG: string;
  NO2_MIN: string;
  NO2_MIN_SI: number;
  NO2_MAX: string;
  NO2_MAX_SI: number;
  NO2_AVG: string;
  NO2_AVG_SI: number;
  NOX_MIN: string;
  NOX_MAX: string;
  NOX_AVG: string;
  NH3_MIN: string;
  NH3_MIN_SI: number;
  NH3_MAX: string;
  NH3_MAX_SI: number;
  NH3_AVG: string;
  NH3_AVG_SI: number;
  BENZENE_MIN: string;
  BENZENE_MAX: string;
  BENZENE_AVG: string;
  TOLUENE_MIN: string;
  TOLUENE_MAX: string;
  TOLUENE_AVG: string;
  ETHYL_BENZENE_MIN: string;
  ETHYL_BENZENE_MAX: string;
  ETHYL_BENZENE_AVG: string;
  O_XYLENE_MIN: string;
  O_XYLENE_MAX: string;
  O_XYLENE_AVG: string;
  MP_XYLENE_MIN: string;
  MP_XYLENE_MAX: string;
  MP_XYLENE_AVG: string;
  date: string;
  hour: number;
  AQI: number;
}

export interface realtimeHumidityTemperatureDateType {
  temperature: string;
  humidity: string;
  pressure: string;
  timestamp: string;
  solar_radiation: number;
  rainfall_intensity: any;
  wind_direction: number;
  wind_speed_mps: string;
}

function DtlsPage() {
  const { state } = useLocation();
  const [stnData, setStnData] = useState([]);
  const [latestData, setLatestData] = useState<AirQualityData[]>([]);
  const [selectDevice, setSelectDevice] = useState<any>();

  const [realtimeHumidityTemperature, setRealtimeHumidityTemperature] =
    useState<realtimeHumidityTemperatureDateType | null>(null);

  const GetStationId = async () => {
    try {
      const res = await fetch(
        "https://caaqms.wbpcb.wtlprojects.com:9100/get_all_station"
      );
      const apiData = await res.json();
      console.log("apiData ==> ", apiData.data);
      setStnData(apiData?.data);
      setSelectDevice(apiData?.data[0]);
    } catch (error) {}
  };
  const loadData = async () => {
    try {
      const res2 = await fetch(
        `https://caaqms.wbpcb.wtlprojects.com:9100/v1.0/sens/real/get_24h_aqi?station_id=${selectDevice?.id}`
      );

      const apiData2 = await res2.json();
      console.log("apiData2 ==> ", apiData2.data);
      setLatestData(apiData2?.data);

      // Get realtime humidity & temperature
      await fetch(
        `https://caaqms.wbpcb.wtlprojects.com:9100/v1.0/sens/real/get_current?station_id=${selectDevice?.id}`
      )
        .then((result) => result.json())
        .then((result) => {
          setRealtimeHumidityTemperature(result?.data);
          console.log(result?.data);
        });
    } catch (error) {}
  };

  useEffect(() => {
    selectDevice && loadData();
  }, [selectDevice]);

  useEffect(() => {
    GetStationId();
  }, []);

  return (
    <div className="bg-gray-200 min-h-screen">
      <Header />

      <div className=" flex items-center justify-end mt-2 sm:pr-2">
        <DeviceDropdown
          devices={stnData}
          onSelect={(e) => {
            console.log(e);
            setSelectDevice(e);
          }}
        />
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-5">
        <div className="col-span-2">
          <Map stnData={stnData} />
        </div>
        <div>
          <AQIGauge aqi={latestData[0]?.AQI} />
        </div>
        <div className="col-span-2">
          <InfoCard
            stnData={selectDevice}
            realtimeHumidityTemperature={realtimeHumidityTemperature}
          />
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3">
        <div className="sm:col-span-2">
          <ChatGraph selectedStation={selectDevice} />
        </div>
        <BasicPollutant latestData={latestData} />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-5 gap-0 sm:gap-2 ">
        <div className="col-span-1 sm:col-span-3 mb-2 sm:mb-0 ">
          <AQILabelCard />
        </div>
        <AllPollutant latestData={latestData} />
      </div>
    </div>
  );
}

export default DtlsPage;
