import GoogleMap from "google-maps-react-markers";
import React, { useRef } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { AqiColorCodeString } from "../Utils/HealperFN";

const AnyReactComponent: React.FC<{
  lat: number;
  lng: number;
  aqi: number;
  location: string | null;
}> = ({ lat, lng, aqi, location }) => {
  return (
    <div
      className={`h-10 w-10  rounded-full  shadow-lg cursor-pointer items-center border border-white`}
      style={{
        backgroundColor: AqiColorCodeString(aqi),
      }}
      data-tooltip-id={lat.toString()}
    >
      <p className="font-medium text-black text-center text-sm  pt-[0.6rem]">
        {aqi}
      </p>
      <ReactTooltip id={lat.toString()} place="bottom">
        <p className="font-semibold text-sm text-white">{location}</p>
      </ReactTooltip>
    </div>
  );
};

export interface IStnData {
  id: number;
  name: string;
  location: null | string; // Location can be null or a string
  created_at: string; // Assuming this is a timestamp in string format
  lat: string;
  long: string;
  date: string;
  hour: number;
  AQI: number;
  prominent_pollutent: string;
  humidity: string;
  temperature: string;
}

export const Map: React.FC<{
  stnData: IStnData[];
}> = ({ stnData }) => {
  const mapRef = useRef(null);
  const onGoogleApiLoaded = ({ map, maps }: any) => {
    mapRef.current = map;
    // setMapReady(true)
  };
  return (
    <div className="h-[16rem] col-span-2 overflow-hidden rounded-lg shadow-md sm:ml-4 mt-3">
      <GoogleMap
        apiKey={
          process.env.NODE_ENV === "production"
            ? process.env.REACT_APP_GOOGLEMAP_API_KEY_PROD
            : process.env.REACT_APP_GOOGLEMAP_API_KEY_DEV
        }
        defaultCenter={{ lat: 22.7605581, lng: 88.3617589 }}
        center={{ lat: 22.7605581, lng: 88.3617589 }}
        defaultZoom={6}
        zoom={6}
        onGoogleApiLoaded={onGoogleApiLoaded}
      >
        {stnData?.map((k) => (
          <AnyReactComponent
            lat={parseFloat(k.lat)}
            lng={parseFloat(k.long)}
            aqi={k.AQI}
            location={k.location}
            key={k.id}
          />
        ))}
      </GoogleMap>
    </div>
  );
};

export default Map;
