import React, { useEffect, useState } from "react";
import { InputActionMeta } from "react-select";
import Select from "react-select";

type Props = {
  devices: any;
  onSelect: (ele: any) => void;
};

const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: "#ffffff",
    border: state.isFocused ? 0 : 0,
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: state.isFocused ? 0 : 0,
    },
  }),
};

const DeviceDropdown = ({ devices, onSelect }: Props) => {
  const [selectDevice, setSelectDevice] = useState({
    label: devices[0]?.location,
    value: devices[0]?.id,
  });

  useEffect(() => {
    setSelectDevice({
      label: devices[0]?.location,
      value: devices[0]?.id,
    });
  }, [devices]);
  return (
    <div className="bg-blue-400 w-full mx-2 sm:mx-2 sm:w-1/5 rounded-md">
      <Select
        styles={customStyles}
        theme={(theme) => ({
          ...theme,
          //   borderRadius: 0,
          colors: {
            ...theme.colors,
            // primary25: "#0D8f87",
            primary: "#0D8f87",
          },
        })}
        className="w-full text-black col-span-1 z-40 "
        isSearchable
        placeholder="Station Name"
        onChange={(e: any) => {
          const newDevice = devices.find((d: any) => d?.id === e?.value);
          if (!!newDevice) {
            onSelect(newDevice);
            setSelectDevice(e);
          }
        }}
        options={[
          ...devices.map((k: any) => ({ label: k.location, value: k.id })),
        ]}
        inputValue={""}
        onInputChange={() => {}}
        value={selectDevice}
      />
    </div>
  );
};

export default DeviceDropdown;
